<script>
    let startingYear = 2021
    let currentYear = new Date().getFullYear()

    let copyright = startingYear == currentYear ? startingYear : `${startingYear} - ${currentYear}`
</script>

<div class="bg-rigged-gray-500">
    <div class="container mx-auto px-4 py-5">
        <p class="text-white">Copyright &copy; {copyright} Rigged Development. All rights reserved.</p>
    </div>
</div>

