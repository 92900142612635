<script>
    import Header from "./Header.svelte";
</script>

<div class="h-screen container mx-auto px-4 py-5 flex flex-col justify-between">
    <Header></Header>

    <div class="self-center justify-self-center">
        <h1 class="font-black lg:text-9xl md:text-8xl sm:text-7xl text-6xl uppercase text-center select-none">Rigged.</h1>
        <h2 class="font-black lg:text-8xl md:text-7xl sm:text-6xl text-5xl uppercase text-center tracking-widest select-none">Software</h2>
    </div>
    <div class="h-10">
    </div>
</div>

<style>
    h1 {
        font-family: 'Montserrat', sans-serif;
        line-height: 0.8;
        background: rgb(253,130,0);
        background: linear-gradient(45deg, rgba(253,130,0,1) 0%, rgba(249,71,0,1) 100%);
        background-clip: text;
        color: transparent;
        filter: drop-shadow(0px 0px 50px rgba(255, 132, 1, 0.651));
    }

    h2 {
        font-family: 'Source Code Pro', monospace;
        line-height: 0.8;
        color: whitesmoke;
    }
</style>