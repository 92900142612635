<script>
  import Hero from "./Hero.svelte";
  import Footer from "./Footer.svelte"
</script>

<main class="bg-rigged-gray-900 min-h-screen">
  <Hero></Hero>
  <Footer></Footer>
</main>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer utilities {
    .drag-none {
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  :root::-webkit-scrollbar {
    display: none;
  }

  :root{ 
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  } 
</style>
